








































@import '@design';
@import '@styleMixins';

.page-title {
  &,
  > p {
    margin: 0;
  }
}
